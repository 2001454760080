
import {computed, defineComponent, watch} from "vue";
import {useDocUserStore} from "@/store/doc.user.store";
import {GetSpaceId, PinaLoadEntity} from "@/core/composite/composite";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PageStatus from "@/views/doc/PageStatus.vue";
import FAQ from "@/views/doc/FAQ.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DocTrainingProgress from "@/views/training/DocTrainingProgress.vue";
import File from "@/views/doc/File.vue";
import DocsRelated from "@/views/training/DocsRelated.vue";

export default defineComponent({
  name: "TrainingDoc",
  components: {DocsRelated, File, DocTrainingProgress, FAQ, PageStatus, DateTimeFormat},
  props: {
    id: {type: String}
  },
  setup() {
    const docUserStore = useDocUserStore();
    const spaceId = GetSpaceId().spaceId;
    const entity = computed<any>(() => docUserStore.entity);

    watch(entity, (cb: any) => {
      const pageTitle = 'Document'

      const data: any = []

      data.push({
        link: true,
        router: `/` + spaceId.value + '/training/progress',
        text: 'Training'
      })
      if (cb.parent) {
        data.push({
          link: true,
          router: `/` + spaceId.value + '/training-lib/' + cb.parent.id,
          text: cb.parent.name
        })
      }

      data.push({link: false, router: '', text: cb.doc.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })

    return {
      entity,
      spaceId,
      ...PinaLoadEntity(docUserStore)
    }
  },
  methods: {}
})


import {defineComponent, ref} from "vue";
import DocUserService from "@/core/services/DocUserService.ts"
import Swal from "sweetalert2";
import {useToast} from "vue-toast-notification";
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";
import {useDocUserStore} from "@/store/doc.user.store";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "DocTrainingProgress",
  components: {Row, TrainingUserStatus},
  props: {
    doc: {type: Object, required: true},
    status: {}
  },
  setup() {
    const docUserStore = useDocUserStore();
    const submitting = ref(false);
    return {
      docUserStore,
      submitting,
    }
  },
  methods: {
    markComplete() {
      this.submitting = true;
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocUserService.complete(this.doc.id).then(() => {
        useToast().info("Updated completed", {position: 'top-right'})
        this.docUserStore.get(this.doc.id);
      }).finally(() => {
        this.submitting = false;
        Swal.close();
      })
    },
    markUnComplete() {

    }

  }
})

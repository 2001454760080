
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Panel from "@/components/base/panel/Panel.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import {useDocumentStore} from "@/store/document.store";
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "File",
  components: {BaseForm, Panel, QuickAction},
  props: {
    docId: {},
    canAddFile: {default :true},
  },
  setup(props) {
    const documentStore = useDocumentStore()
    const page = computed(() => documentStore.page);
    const doc = computed(() => store.state.DocModule.pageEntity.data);
    const filterObject = ref<any>({docId: props.docId})
    const state = ref<any>({
      show: false,
      submitting: false,
      model: {file: '', docId: ''}
    })
    return {
      filterObject,
      documentStore,
      page,
      doc,
      ...LoadPanel(),
      state,
      ...PinaFilterObjects(documentStore, filterObject.value, [])
    }
  },
  watch: {
    docId(cb) {
      this.filterObject.docId = cb;
      this.updateFilterObject(this.filterObject);
    }
  },
  methods: {
    onClosePanel() {
      this.state.show = false;
    },
    addFile() {
      this.state.model = {file: '', docId: this.docId}
      this.state.show = true;
    },
    handleFileChange(e: any) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.state.model.file = e.target.files[0]
    },
    onSubmit() {
      this.state.submitting = true;
      DocumentService.upload(this.state.model).then(() => {
        this.paginationDataLoad()
        this.state.show = false
      }).finally(() => {
        this.state.submitting = false;
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await DocumentService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
    onDownload(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocumentService.download(id).then(res => {
        makeDownloadLink(res)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    }
  }
})


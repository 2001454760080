
import {defineComponent, ref, watch} from "vue";
import {GetSpaceId} from "@/core/composite/composite";
import DocUserService from "@/core/services/DocUserService";

export default defineComponent({
  name: "DocsRelated",
  props: {
    parentId: {},
    docId: {}
  },
  setup(props) {
    const loading=  ref(false);
    const documents = ref<any>([]);
    const loadDocs = (id) => {
      loading.value = true;
      DocUserService.getDocs(id).then(res => {
        documents.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadDocs(props.parentId);
    watch(() => props.parentId, cb => {
      loadDocs(cb);
    })
    return {
      documents,
      ...GetSpaceId()
    }
  }
})

import {defineStore} from "pinia";
import DocUserService from "@/core/services/DocUserService";
import {useLoadingStore} from "@/store/loading";

export const useDocUserStore = defineStore('docUserStore', {
  state: () => ({
    page: <any>{total: 0, data: [], totalPages: 0},
    entity: <any>{},
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true)
      DocUserService.findAll(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false);
      })
    },

    get(id) {
      DocUserService.get(id).then(res => {
        this.entity = res;
      })
    }
  }
})
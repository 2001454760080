
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import ShortPageForm from "@/views/doc/ShortPageForm.vue";
import Panel from "@/components/base/panel/Panel.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "FAQ",
  components: {Panel, ShortPageForm, QuickAction},
  props: {
    docId: {type: String}
  },
  setup(props) {
    const state = ref({title: '', show: false, id: null})
    const model = ref<any>({name: '', type: '', body: '', parentId: '', description: '', status: ''})
    const faqs = ref<any>([])
    const loadData = (id) => {
      DocService.getFAQ(id).then(res => {
        faqs.value = res;
      })
    }
    loadData(props.docId);
    watch(() => props.docId, cb => {
      loadData(cb);
    })

    return {
      loadData,
      state,
      model,
      faqs,
    }
  },
  methods: {
    onClosePanel() {
      this.state.show = false;
    },
    onSavePage() {
      this.loadData(this.docId)
      this.state.show = false;
    },
    onEdit(page) {
      this.model.name = page.name;
      this.model.type = page.type;
      this.model.parentId = page.parentId
      this.model.body = page.body;
      this.model.status = page.staus;
      this.model.description = page.description
      this.state.title = 'Edit FAQ'
      this.state.show = true
      this.state.id = page.id
    },
    onAdd() {
      this.model.name = '';
      this.model.type = 'FAQ';
      this.model.parentId = this.docId
      this.model.body = '';
      this.model.status = 'PUBLISHED';
      this.model.description = ''
      this.state.title = 'Add FAQ'
      this.state.show = true
      this.state.id = null
    },
    onDeleteItem(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await DocService.delete(page.id).then(() => {
            this.loadData(this.docId)
          })
        }
      })
    }
  }
})
